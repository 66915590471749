// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// Import style for flatpickr
//require("flatpickr/dist/flatpickr.css")

// you can also import a translation file
import { Slovenian } from 'flatpickr/dist/l10n/sl.js'

import 'flatpickr/dist/flatpickr.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  connect() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      enableTime: true,
      time_24hr: true,
      dateFormat: "d.m.Y H:i",
      disableMobile: "true",
      locale: Slovenian
    };

    super.connect();
  }
}
