// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("moment/locale/sl") // locale for datepicker
require("tempusdominus-bootstrap-4") // datetime picker for bootstrap
require("bootstrap")

// CSS
import 'scss/application'

// custom js
import "custom/datetimepicker"

// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-free/js/all";

import "controllers"
