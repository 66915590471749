$(document).on('turbolinks:load', function () {
  $('#datetimepicker1').datetimepicker({
    sideBySide: true
  });
  $('#datetimepicker2').datetimepicker({
    sideBySide: true
  });
  $('#datetimepicker3').datetimepicker({
    format: 'L'
  });
});
document.addEventListener("visibilitychange", function() {
   if (document.hidden){
       console.log("Browser tab is hidden. Need to refresh time on reopen.")
   } else {
       location.reload();
   }
});
