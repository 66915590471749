import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "add_part",
                    "itemTemplate", "partTemplate",
                    "heavy_equipment", "heavy_subequipment",
                    "obdelava", "priprava", "izdelava", "montaza", "drobni",
                    "del", "enota", "cena", "total"]

  initialize() {
    this.create_item_field()
  }

  add_part_association(event) {
    event.preventDefault()
    this.create_part_field(event)
  }

  add_item_association(event) {
    event.preventDefault()
    if (this.num_of_fields < 10) {
      this.create_item_field()
    }
  }

  remove_item_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
    let fields = event.target.closest(".heavy_equipment")
    fields.remove()
  }

  remove_part_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-subfields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.remove()
  }

  create_item_field() {
    this.add_itemTarget.insertAdjacentHTML('beforebegin', this.itemTemplateTarget.innerHTML)
  }

  create_part_field(event) {
    let item = event.target.closest(".heavy_equipment")
    item.insertAdjacentHTML('beforebegin', this.partTemplateTarget.innerHTML)
  }

  calculate_total(event){
    event.preventDefault()

    let total = 0
    total += this.multiplyFieldValues(this.delTargets, this.enotaTargets, this. cenaTargets)
    total += this.addFieldValues(this.obdelavaTargets)
    total += this.addFieldValues(this.pripravaTargets)
    total += this.addFieldValues(this.izdelavaTargets)
    total += this.addFieldValues(this.montazaTargets)
    total += this.addFieldValues(this.drobniTargets)

    this.totalTarget.innerHTML = total
  }

  get num_of_fields() {
    return this.heavy_equipmentTargets.length
  }

  addFieldValues(fields){
    let sum = 0
    fields.forEach((i) => {
      if(i.value != "")
        sum += parseFloat(i.value)
    })
    return sum
  }

  multiplyFieldValues(deli, enote, cene){
    let sum = 0
    deli.forEach((del,i) => {
      if(del.value != "" && enote[i].value != ""  && cene[i].value != "")
        sum += parseFloat(del.value)*parseFloat(enote[i].value)*parseFloat(cene[i].value)
    })
    return sum
  }
}
